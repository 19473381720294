import React from 'react'
import { Container, Box, Flex, Heading, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  listItem: {
    alignItems: `stretch`,
    justifyContent: `flex-start`
  },
  line: {
    position: `absolute`,
    bottom: `10px`,
    left: `50%`,
    width: `1px`,
    height: t => `calc(100% - ${t.sizes.icon.md + 20}px)`,
    transform: `translateX(-50%)`,
    zIndex: 1,
    bg: `omega`
  },
  number: {
    color: `white`,
    fontWeight: `body`,
    borderRadius: `0`,
    bg: `alpha`,
    size: `icon.md`,
    textAlign: `center`,
    p: 2,
    mb: 0
  }
}

const FeaturesWithPhotoBlock03 = ({
  content: { text, buttons, collection, images }
}) => (
  <Container sx={{ textAlign: `start` }}>
    <Flex sx={{ flexDirection: [`column`, null, `row`], m: [0, -4] }}>
      <Box
        sx={{
          flexBasis: [`1`, `1/2`],
          alignSelf: `center`,
          mx: 4,
          mb: [5, null, 0]
        }}
      >
        <Reveal effect='fadeInDown'>
          {images && (
            <>
              <Divider space={3} />
              <Box sx={{ position: `relative` }}>
                <ContentImages
                  content={{ images }}
                  loading='eager'
                  imagePosition='center'
                  imageEffect='fadeInDown'
                />
              </Box>
            </>
          )}
        </Reveal>
      </Box>
      <>
        {collection && (
          <Box
            sx={{
              flexBasis: [`1`, `1/2`],
              alignSelf: `start`,
              mx: 4
            }}
          >
            <Flex sx={{ flexWrap: `wrap` }}>
              <Box sx={{ textAlign: `left` }}>
                <ContentText content={text} />
                <Divider space={3} />
              </Box>
              {buttons && (
                <>
                  <Divider space={3} />
                  <ContentButtons content={buttons} />
                </>
              )}

              {collection.map(({ text }, index) => (
                <Reveal
                  key={`item-${index}`}
                  effect='fadeInDown'
                  delay={0.2 * (index + 1)}
                  css={css({ flexBasis: [`1`, null, null, `1`] })}
                >
                  <Flex sx={styles.listItem}>
                    <Box sx={{ position: `relative`, flexShrink: 0, mr: 4 }}>
                      <Heading variant='h4' as='div' sx={styles.number}>
                        {index + 1}
                      </Heading>
                      {/* {index + 1 < collection.length && (
                        <Box sx={styles.line} />
                      )} */}
                    </Box>
                    <Box>
                      <ContentText content={text} />
                      {index + 1 < collection.length && <Divider space={3} />}
                    </Box>
                  </Flex>
                </Reveal>
              ))}
            </Flex>
          </Box>
        )}
      </>
    </Flex>
    {console.log(`images: `, images)}
  </Container>
)

export default WithDefaultContent(FeaturesWithPhotoBlock03)
